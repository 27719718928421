import React from 'react'
import { Image } from 'react-native'
import styled from 'styled-components/native'
import Button from '../../button'
import Images from '../../../constants/Images'

interface IFeedbackHeader {
  handleClose: () => void;
}
export default function FeedbackHeader(props: IFeedbackHeader) {
  return (
    <Header>
      <LogoContainer>
        <Image source={require('../../../assets/images/logos/UHC_NVG_COBRAND_UHCBLUE_262x55px.svg')} resizeMode="contain" style={{height: '100%', width: '100%'}} />
      </LogoContainer>
      <Button onPress={props.handleClose} icon={true} iconName="md-close"
      iconStyle={{color:'#0074C8'}}
      hoveredStyle={{color:'#9F2140'}} />
    </Header>
  )
}

const Header = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 26px;
`
const LogoContainer = styled.View`
  height: 32px;
  width: 199px;
`